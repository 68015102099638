@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;900&display=swap');

$accent: #002F5B;
$accent-dark: #002445;
$accent-light: #D3E2F0;
$primary: #FF6600;
$gc-burnt-orange:#650A33;
$btn-hover:#CC5200;
$dark: #222;
$neutral: #6A7181;
$danger: #850000;

$neutral-50: #F9FAFA;
$neutral-100: #F2F2F2;
$neutral-600: #4E545F;
$neutral-700: #3E434C;
$neutral-800: #272A30;

$primary-font: 'Roboto';

.medium-font {
  font-weight: 500;
}

.semi-bold-font {
  font-weight: 600;
}

.bold-font {
  font-weight: 700;
}

body, .App {
  font-family: $primary-font;
}

h1 {
  color:$primary;
}

h4 {
  font-size: 1.2rem;
}

a {
  text-decoration: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  //background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #333;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// Buttons
.btn {
  font-family: $primary-font;
  font-size: 14px;
  font-weight: 500;
  border-radius: 2px;
  &.btn-primary {
    background: $primary;
    border-color: $primary;
  }
  &.btn-outline-primary {
    border-color: $primary;
    color: $primary;
  }
}

.page-content {
  padding: 1rem;
  margin-left: 64px;
}
// Sidenav
.custom-sidenav {
  background: $accent;
  position: fixed;
}

.raise-by {
  div.grey-label {
    background-color: $neutral-100;
    border-radius: 24px;
    span {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      font-size: 12px;
      font-weight: 600;
      background: $accent;
      color: #fff;
      border-radius: 50%;
      margin-right: 10px;
    }
    label {
      font-size: 1rem;
      color: $neutral;
      font-weight: 500;
  
      &.value {
        font-size: 1rem;
        font-weight: 500;
        color: $accent-dark;
      }
    }
  }
}

.ai-content {
  font-size: 14px;
  color: $neutral-600;
  font-weight: 500;
  span {
    span {
      font-size: 14px;
      color: $accent;
      font-weight: 500;
    }
    label {
      color: $primary;
      font-size: 15px;
    }
  }
}




// Case Detail
.case-detail-wrapper {
  .case-customer-detail {
    border-left: 1px solid #dedede;
    padding: 0.5rem 1rem;
  }
  .customer-username {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    span {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      font-size: 20px;
      font-weight: 600;
      background: $accent;
      color: #fff;
      border-radius: 50%;
    }
  }
  .customer-contact {
    padding: 0;
    list-style: none;
    margin: 0;
    li {
      i {
        margin-right: 10px;
      }
    }
  }
  .customer-case-history {
    h3 {
      font-size: 18px;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      font-size: 14px;
      li {
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .case-detail {
    .case-header {
      display: flex;
      justify-content: space-between;
      h2 {
        font-size: 30px;
        font-weight: 400;
      }
    }
    .case-actions {
      button {
        margin-left: 0.75rem;
        svg {
          margin-right: 0.75rem;
        }
        &.btn-skip {
          border-color: $accent;
          color: $accent;
          svg {
            
          }
        }
        &.btn-assign {
          border-color: $primary;
          color: $primary;
          svg {
            
          }
        }
        &.btn-go {
          border-color: $primary;
          color: $primary;
          svg {
            
          }
        }
        &.btn-spam {
          border-color: $danger;
          color: $danger;
          svg {
            
          }
        }
      }
    }
    .label-case-date {
      font-size: 1rem;
    }
    .case-date {
      font-size: 1rem;
      color: $neutral;
      font-weight: 500;
    }
    .case-descriptor {
      display: flex;
      min-width: 250px;
      flex-direction: column;
      align-items: start;
      justify-content: flex-start;
      label {
        font-size: 13px;
      }
      .badge {
        margin-right: 0.5rem;
        color: $dark;
        font-weight: 500;
        border-radius: 30px;
        color: $dark;
        text-transform: uppercase;
          i {
            display: inline-flex;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            margin-right: 0.5rem;
          }
          &.urgent {
            background: lighten($danger, 65%);
            i {
              background: $danger;
            }
          
        }
      }
    }
  }
  
}
.case-description {
  max-height: 500px;
  overflow-y: auto;
  font-size: 1rem;
  font-weight: 600;
  color: $neutral-700;
}

.case-descriptor{
  .badge{
    font-size: 16px;
  }
}
/*
*
*** Custom table stylings
*
*/

$cstm-table-striped-bg: #F9FAFA; // Striped background color 
$cstm-table-outline: #E6E8EE; // Table outline color
$cstm-table-hover-bg: #FFF7EF; // Hover background color

.case-list-table {
  //Table striped bg color
  .table-striped > tbody > tr:nth-child(odd) > td, 
  .table-stripe > tbody > tr:nth-child(odd) > th {
    background-color:$cstm-table-striped-bg;
    --bs-table-bg-type: $cstm-table-striped-bg
  }

  //Table hover bg color
  .table-hover tbody tr:hover td, .table-hover tbody tr:hover th{
    background-color: $cstm-table-hover-bg;
    --bs-table-hover-bg: $cstm-table-hover-bg;
    color:$btn-hover;
  }

  //Customizing new pill bg-color
  .case-list.table-striped > tbody > tr > td > span.label-new {
    background-color: $accent;
  }
}


/*
*
*** Pagination styling
*
*/
 .custom-pagination {
  --bs-pagination-color: #272A30;
  --bs-pagination-hover-color:#FF6600;
  --bs-pagination-active-bg: #FF6600;
  --bs-pagination-active-border-color:#FF6600;
 }


 /*
*
*
*---- Case page
*
***
*
*/

.label-email-body {
  color: $gc-burnt-orange;
  font-weight: 600;
}

.side-card-button {
    width:100%;
    border-radius: 24px;

    &.btn-secondary {
      background-color: $cstm-table-hover-bg;
      color: $btn-hover;
    }
}

.secondary-cta {
    background-color: $accent !important;
    border-radius:24px;
    border: 2px solid $accent !important;
}

.case-label {
  font-size: 1.1rem;
  font-weight: 500;
  color: $neutral-700;
}

.assigned-value {
  
    span {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      font-size: 12px;
      font-weight: 600;
      background: $accent;
      color: #fff;
      border-radius: 50%;
      margin-right: 10px;
    }
    label {
      font-size: 1.2rem;
      font-weight: 500;
      color: $accent-dark;
      &.regular {
        font-weight: 400;
        color: $neutral-700;
      }
    }
}

.no-label-text-deco {
 text-decoration: none !important;
}

.side-card {
  background-color: $neutral-50;
  border-radius: 16px;
  border: 1px solid $neutral-100;
}